import { DispatchConfiguration } from './interfaces/product';

class UiPrez {
  prefered_prez_demand_uom: string;
  event_management_hex_code: string;
  ramp_up: number;
  ramp_down: number;
  hide_cleared_tab: boolean;
  hide_baselines_tab: boolean;
  constructor(){}
}
export { UiPrez }

export interface ProductAPI {
  id: string;
  display_labels: object;
  descriptions: object;
  status:	string;
  default_locale: string;
  timezone: string;
  create_dttm: string;
  update_dttm: string;
  discontinue_dttm: string;
  updated_by: string;
  alternate_ids: object;
  program_id: string;
  advance_notice: number;
  baseline_capacity: boolean;
  bonus_minutes: number;
  default_nomination_schedule: boolean;
  dispatch_method: string;
  start_dttm: string;
  end_dttm: string;
  equipment_interval_frequency: number;
  include_nomination_in_comms: boolean;
  last_status_dttm: string;
  max_event_duration: number;
  max_event_hours_per_season: number;
  max_events_per_season: number;
  maximum_consecutive_durations: number;
  maximum_invocations_per_duration: number;
  maximum_notification_duration: number;
  maximum_response_duration: number;
  min_event_duration: number;
  min_duration_between_events: number;
  minimum_notification_duration: number;
  minimum_recovery_duration: number;
  minimum_response_duration: number;
  response_time: number;
  weather_ceiling_limit_pct: number;
  weather_factor_id: number;
  weather_floor_limit_pct: number;
  weather_window_basis: boolean;
  selection_buffer_min: number;
  selection_buffer_max: number;
  selection_upper_target: number;
  default_dispatch_duration: number;
  schedule: object;
  gate_rules: object;
  prez_conf: UiPrez;
  selection_type:	string;
  performance_aggregate_type:	string;
  target_type:	TargetType;
  underfrequency_product: boolean;
  control_timeout: number;
  performance_target_min: number;
  performance_target_max: number;
  collect_notification_time: boolean;
  reporting_interval_ms: number;
  key_performance_metric: string;
  dispatch_conf: DispatchConfiguration;
}

export interface Product {
  id: string;
  timezone: string;
  target_type: TargetType;
  performance_target_min: number | null;
  performance_target_max: number | null;
  max_event_duration: number;
  bonus_minutes: number;
  collect_notification_time: boolean;
  reporting_interval_ms: number;
  key_performance_metric: string;
  dispatch_conf: DispatchConfiguration;
}
export function parse(product: ProductAPI): Product {
   return{
     id: product.id,
     timezone: product.timezone,
     reporting_interval_ms: product.reporting_interval_ms || 300000,
     target_type: product.target_type || TargetType.DROP_BY,
     performance_target_min: product.performance_target_min,
     performance_target_max: product.performance_target_max,
     max_event_duration: product.max_event_duration,
     bonus_minutes: product.bonus_minutes,
     collect_notification_time: product.collect_notification_time,
     key_performance_metric: product.key_performance_metric,
     dispatch_conf: product.dispatch_conf
   };
 }

export enum TargetType {DROP_TO = 'DROP_TO', RANGE = 'RANGE', DROP_BY = 'DROP_BY'}
