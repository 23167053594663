import { ApiTime } from 'common-utils/dist/models/time';
import { WithNulls } from 'common-utils/dist/typescript-utils';
import { Event } from './event';
import {
  composer as comparatorComposer,
  factory as comparatorFactory,
  MetaModel,
  WithId,
  WithLabel,
  WithPerformancePercent,
  WithUomValue
} from './shared';
import { orNaN } from './shared/utils';

export interface EventNodeAPI {
  // `display_label` is in fact the `site_display_label` processed from the presentation layered and renamed
  // see https://github.bdap.enernoc.net/HoneyBadgers/event-performance-dashboard/blob/6320cf2b4668c9755ef24fc6982296551078d896/api/src/Services/LocationService.js
  display_label: string; // no longer used / needed, see `site_display_label` below
  event_node_id: string;
  event_id: string;
  participation_plan_id: string;
  site_id: string;
  site_display_label: { [posixLocale: string]: string };
  is_fsl_indicator: boolean;
  is_generator_indicator: boolean;
  firm_service_level_value: number | null;
  firm_service_level_uom: string | null;
  expected_capacity_value: number | null;
  expected_capacity_uom: string | null;
  registered_capacity_value: number | null;
  registered_capacity_uom: string | null;
  last_current_metered_value: number | null;
  last_current_metered_uom: string | null;
  last_current_performance_value: number | null;
  last_current_performance_uom: string | null;
  last_current_performance_status: string | null;
  last_current_performance_percentage: number | null;
  last_current_performance_dttm_utc: string | null;
  last_current_performance_dttm_program_locale: string | null;
  last_current_target_value: number | null;
  average_performance_value: number | null;
  average_performance_uom: string | null;
  average_performance_status: string | null;
  average_performance_percentage: number | null;
  bonus_value: number | null;
  bonus_uom: string | null;
  event_node_start_dttm_utc: string;
  event_node_end_dttm_utc: string;
  initial_notif_time: string;
  registration_type: string;
  estimate_performance_ind: boolean;
  adjustment_window_start: string;
  adjustment_window_end: string;
  minimum_performance_value: number;
  minimum_performance_percentage: number;
  minimum_performance_uom: string;
  maximum_performance_value: number;
  maximum_performance_percentage: number;
  registration_id: string;
  site_to_registration_map: string[];
}

export interface EventNode extends MetaModel<EventNodeAPI>, WithId {
  event: Event;
  site: WithId & WithLabel;
  flags: {
    is_fsl_indicator: boolean;
  };
  fsl: WithNulls<WithUomValue>;
  expected_capacity: WithNulls<WithUomValue>;
  last_current_performance_dttm: ApiTime | null;
  last_current_metered: WithNulls<WithUomValue>;
  performance: WithNulls<WithUomValue & WithPerformancePercent>;
  last_current_performance_percentage: number | null;
  last_current_target_value: number | null;
  event_node_start: ApiTime | null;
  event_node_end: ApiTime | null;
  program_time_zone_abbr: string;
  initial_notif_time: string;
  registration_type: string;
  estimate_performance_ind: boolean;
  pre_event_buffer: number; // in milliseconds
  post_event_buffer: number; // in milliseconds
  adjustment_window_start: string;
  adjustment_window_end: string;
  registration_id: string;
  site_to_registration_map: string[];
}

const compareByPerformancePercent = comparatorFactory<EventNode, number>(({ performance: { percentage } }) => orNaN(percentage));
const compareByExpectedCapacity = comparatorFactory<EventNode, number>(({ expected_capacity: { value } }) => orNaN(value));
const compareByDisplayLabel = comparatorFactory<EventNode, string>(({ site: { display_label } }) => display_label);

export const comparators = {
  byPerformanceOrCapacityOrLabel: comparatorComposer(compareByPerformancePercent, compareByExpectedCapacity, compareByDisplayLabel),
  byLabel: compareByDisplayLabel,
  byCapacityOrPerformanceOrLabel: comparatorComposer(compareByExpectedCapacity, compareByPerformancePercent, compareByDisplayLabel),
};

export enum  RegistrationType {
  LOAD = 'LOAD',
  GENERATOR = 'GENERATOR',
  STORAGE = 'STORAGE',
  DEMAND_ON = 'DEMAND_ON',
  LOAD_METERED_GENERATOR = 'LOAD_METERED_GENERATOR',
  LOAD_DROP_TO = 'LOAD_DROP_TO'
}
