export const baselineAdjustmentWhitelist = {
  "prd": [
    "dcdedb10-2e86-4a1c-8e71-50b21e4907f1",
    "8e232c01-5a67-405c-8f17-2ea0f85ea571",
    "f219768c-1d5f-4625-b5ec-a14f22cafa4f",
    "d19e014f-4861-46e0-8a95-080bb9a2031c",
    "4d64326b-79a9-4d7d-a839-a531f939ff55",
    "bc5bd4f4-da67-4724-9395-b9c3c7d36485",
    "7ed93b19-577d-4264-9dbf-eb7845091a6f",
    "36d1c0b4-4155-4493-a828-6c85fef3b0d2",
    "47034175-3563-4396-87dc-0bd16ab581ad",
    "3adb1733-7ea2-4eec-85e0-1fc291b757ca",
    "dca40a99-0927-4af0-9638-8f68ffbfd245",
    "b9191c37-6613-4bfb-ba1e-2b80fbf25dd4",
    "8d642854-97c2-4b00-9ace-5fa236926a8b",
    "d5a479a3-7636-4dc9-ac4c-a1cfaa6c9823",
    "1a575df6-4121-4c3f-9ee2-2c0d27c163ad"
  ],
  "preprd": [
    "7e148880-1152-4471-9daf-2ec4f6a12b8f",
    "5422d38f-090b-4258-b160-e969e4abba08",
    "c272a3bd-5c2b-4a81-9179-4ead82cc4f3b",
    "387e4a33-02fd-4761-9716-b2bc24a5df30",
    "49206ab4-5696-4647-a5e4-338695faf0e9",
    "806448fd-1fe7-4130-98f3-318e619b0085",
    "214daf43-4f38-4c15-9f9c-7f8c1a842b7a",
    "0e01c3f7-1517-44c8-96ea-c5b1c6e5c974",
    "e9588b4e-6a0e-44a2-ba30-94fe63c0d5fe",
    "dc32f569-a617-4fb7-a90e-26af2e2e4180",
    "bc3b8575-6283-4be0-b1fd-156752fd3fab",
    "4b06fd06-718f-4be9-be3c-f0b5d252009c",
    "9c34486e-c3fc-4385-bfc9-ab9bdf2bffcc"
  ],
  "local": [],
  "devtest": [],
  "dev": [],
  "dev-cors": []
}

export const filteredProductIds = {
  "prd": [
    "239d1f41-0e2e-4ebe-8325-48747cd060c5",
    "7726ce1d-5a3b-4b96-ad96-2a002e16f6d7",
    "556ade5c-cc27-49e4-b5cd-2a6d5595b799",
  ],
  "preprd": [
    "805824a8-e908-4c8c-a49e-3bdc3123e637",
    "96da9596-4a64-4a93-a5e3-c32ee90b1c4e",
    "20e3b1bf-6b8d-4385-b42b-9499df5c64c7",
  ],
  "local": [
    "94f42529-db8d-4f3b-8d77-b38c4b46f490",
  ],
  "devtest": [
    "94f42529-db8d-4f3b-8d77-b38c4b46f490",
  ],
  "dev": [
    "94f42529-db8d-4f3b-8d77-b38c4b46f490",
  ],
  "dev-cors": [
    "94f42529-db8d-4f3b-8d77-b38c4b46f490",
  ]
}
