import { isNull } from 'common-utils/dist/typescript-utils';
import { DateTime } from 'luxon';
import { environment } from '../../../../environments/environment';
import { SourceSystem } from '../../../models/event';
import { EventNode, RegistrationType } from '../../../models/event-node';
import { NodePerformance } from '../../../models/node-performance';
import { Product, TargetType } from '../../../models/product';
import { orNaN } from '../../../models/shared/utils';
import { baselineAdjustmentWhitelist as productsWithBaselineAdjustment } from 'src/environments/variables';

function filterByRange<T>([from, to]: [number, number], accessor: (d: T) => number): (d: T) => boolean {
  return (d: T) => {
    const t = accessor(d);
    return from <= t && t <= to;
  };
}

function getTargetValue(node: EventNode, t, b): any {
  if (node.event.product !=null && node.expected_capacity.value !=null && b.baseline.value !== null) {
    const prod = node.event.product;
    const targetType = prod.target_type || TargetType.DROP_BY;
    const perfTargetMin = prod.performance_target_min || 100;
    const perfTargetMax = prod.performance_target_max || (perfTargetMin * 1.5);
    let dropTarget;
    let targetLow;
    let targetHigh;
    if(targetType == TargetType.RANGE){
      targetLow = b.baseline.value - (node.expected_capacity.value  * perfTargetMin / 100);
      targetHigh = b.baseline.value - (node.expected_capacity.value  * perfTargetMax / 100);
      return { x:t, low:targetLow, high:targetHigh, targetVal:b.target.value};
    } else {
      return { x:t, y:orNaN(b.target.value)};
    }
  } else {
    return {x:t, low:null, high:null, targetVal:b.target.value};
  }
}

export function factory(node: EventNode, intervals: NodePerformance[]): DispatchGraphViewModel {
  if (isNull(node.event_node_start) || isNull(node.event_node_end)) {
    throw new Error('Need event start and end dates');
  }

  const es = node.event_node_start;
  const ee = node.event_node_end;
  // @ts-ignore
  const eventRange = [es.time, ee.time] as [number, number];
  // @ts-ignore
  const dataRange = [es.time - node.pre_event_buffer, ee.time + node.post_event_buffer] as [number, number];

  // @ts-ignore
  // @ts-ignore
  const viewModel: DispatchGraphViewModel = intervals
  .filter(filterByRange(dataRange, (d) => d.interval.time))
  .reduce((a, b) => {
    const t = b.interval.time;
    a.baseline.push({x:t, y:orNaN(b.baseline.value), field: 'baseline'});
    a.demand.push({x:t, y:orNaN(b.metered.value), field: 'metered'});
    a.target.push({...getTargetValue(node, t, b), field: 'target', metered: b.metered.value});
    a.performance.push({x:t, y:orNaN(b.performance), field: 'performance', metered: b.metered.value});
    return a;
  }, {
    // @ts-ignore
    from: es.time,
    // @ts-ignore
    to: ee.time,
    dataRange,
    tzShort: DateTime.fromISO(node.event_node_start.fullDate).setZone(node.event.product.timezone).setLocale('en-us').toFormat('ZZZZ'),
    // @ts-ignore
    tzOffset: node.event.event_start.tzOffset.numberOffset,
    timezone: node.event.product?.timezone,
    demand: [],
    baseline: [],
    target: [],
    performance: [],
    product: node.event.product,
    eventNode: node,
    isClassic: node.event.source_system_type === SourceSystem.CLASSIC_DR,
    registrationType: node.registration_type,
    frequency: node.event.product ? node.event.product.reporting_interval_ms : 300000,
    // @ts-ignore
    baselineAdjFrom: new Date(node.adjustment_window_start).getTime(),
    // @ts-ignore
    baselineAdjTo: new Date(node.adjustment_window_end).getTime(),
    // @ts-ignore
    bonusMinutesFrom: node.event.product != null && node.event.product.bonus_minutes ? node.event.event_start.time - node.event.product.bonus_minutes * 60000 : 0,
    // @ts-ignore
    bonusMinutesTo: node.event.product != null && node.event.product.bonus_minutes ? node.event.event_start.time : 0,
    show_baseline_adjustment: productsWithBaselineAdjustment[environment.environment].includes(node.event.product.id),
    show_bonus_minutes: node.event.product != null && (node.event.product.bonus_minutes && node.event.product.bonus_minutes > 0),
    showBaseline: node.event.source_system_type === SourceSystem.CLASSIC_DR || node.registration_type === RegistrationType.LOAD || node.registration_type === RegistrationType.DEMAND_ON || node.registration_type === RegistrationType.LOAD_METERED_GENERATOR,
    showDemand: node.event.source_system_type === SourceSystem.CLASSIC_DR || node.registration_type === RegistrationType.LOAD || node.registration_type === RegistrationType.DEMAND_ON || node.registration_type === RegistrationType.LOAD_METERED_GENERATOR || node.registration_type === RegistrationType.LOAD_DROP_TO,
    showGenLoad: node.registration_type === RegistrationType.GENERATOR || node.registration_type === RegistrationType.STORAGE,
    // @ts-ignore
    showTargetRange: node.event.source_system_type !== SourceSystem.CLASSIC_DR && (node.event.product && node.event.product.target_type === TargetType.RANGE),
} as DispatchGraphViewModel);

  return {
    ...viewModel,
    target: viewModel.target.filter(filterByRange(eventRange, (d) => d.x)),
  };
}

export interface DispatchGraphViewModel {
  readonly from: number;
  readonly to: number;
  readonly dataRange: number[];
  readonly tzShort: string;
  readonly tzOffset: number;
  readonly timezone: string
  readonly baseline: any[];
  readonly demand: any[];
  readonly target: any[];
  readonly performance: any[];
  readonly product: Product;
  readonly eventNode: EventNode;
  readonly isClassic: boolean;
  readonly registrationType: string;
  readonly frequency: number;
  readonly baselineAdjFrom: number;
  readonly baselineAdjTo: number;
  readonly bonusMinutesFrom: number;
  readonly bonusMinutesTo: number;
  readonly show_baseline_adjustment: boolean;
  readonly show_bonus_minutes: boolean;
  readonly showBaseline: boolean;
  readonly showDemand: boolean;
  readonly showGenLoad: boolean;
  readonly showTargetRange: boolean;

}
